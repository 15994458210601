import { memo } from 'react';

import { Col, Container, Row } from '~/Components/Grid/Grid';

import styles from './StatisticsHeader.scss';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { items } from '@wg/wows-entities/const';

export interface StatisticsHeaderItem {
  value: string | number;
  label: string;
}

export interface StatisticsHeader_Props {
  data: Array<StatisticsHeaderItem>;
}

const StatisticsHeader = (props: StatisticsHeader_Props) => {
  if (!props.data || props.data.length === 0) {
    return (
      <Container>
        <Row>
          <Col>{'NO DATA'}</Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row className={styles.row}>
        {props.data.map((item: StatisticsHeaderItem, index: number) => {
          if (item.label === items.MASTERY && !item.value) {
            return null
          }
    
          return (
            <Col className={styles.cell} key={`stat-item-${item.value}-${index}`}>
              {item.label === items.MASTERY ? (
                <WoWSEntity
                  type={items.MASTERY}
                  id={item.value}
                  className={styles.mastery}
                  presentation={{
                    withTooltip: true,
                    renderWithoutFade: true,
                  }}
                />
              ) : (
                <>
                  <div className={styles.title}>{item.value}</div>
                  <div className={styles.label}>{item.label}</div>
                </>
              )}
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default memo(StatisticsHeader);
