import get from 'lodash/get';

import { StatisticsColumn } from '~/Components/Statistics/Statistics';
import { StatisticsHeaderItem } from '~/Components/StatisticsHeader/StatisticsHeader';
import {
  BATTLE_TYPES,
  PVP_BATTLE_TYPES,
  RANK_BATTLE_TYPES,
  RANK_OLD_BATTLE_TYPES,
  SEASONAL_BATTLE_TYPES,
} from '~/constants';
import { roundNumbers } from '~/utils';
import { t } from '~/utils/localization';

import { getBattleType } from './glossary';
import { MASTERY, items } from '@wg/wows-entities/const';

export const getStatisticsHeaderData = (statistics: BattleStat, totalStats: MasteryData, battleType: StatBattleType): Array<StatisticsHeaderItem> => {
  const deadCount = statistics.battles_count - statistics.survived;

  const statisticsData = [
    {
      value: roundNumbers(statistics.battles_count),
      label: t('Battles played'),
    },
    {
      value: roundNumbers((100 / statistics.battles_count) * statistics.wins, true),
      label: t('Victories:duplicated0'),
    },
    {
      value: roundNumbers(statistics.premium_exp / statistics.battles_count),
      label: t('XP'),
    },
    {
      value: roundNumbers(statistics.max_frags),
      label: t('Ships destroyed'),
    },
    {
      value: deadCount ? roundNumbers(statistics.frags / deadCount) : 0,
      label: t('Destruction ratio'),
    }
  ];

  if (battleType === 'pvp') {
    statisticsData.push({
      value: totalStats?.mastery_sign,
      label: items.MASTERY,
    });
  }

  return statisticsData;
};

export const getStatisticsData = (statistics: BattleStat): Array<StatisticsColumn> => {
  const winsPercent = (100 / statistics.battles_count) * statistics.wins;
  const lossesPercent = (100 / statistics.battles_count) * statistics.losses;
  const draws = statistics.battles_count - statistics.wins - statistics.losses;
  const mainBatteryHitsPercent = (100 / statistics.shots_by_main) * statistics.hits_by_main;
  const torpedoesHitsPercent = (100 / statistics.shots_by_tpd) * statistics.hits_by_tpd;
  const capturedPercent = (statistics.control_captured_points / statistics.team_control_captured_points) * 100;
  const droppedPercent = (statistics.control_dropped_points / statistics.team_control_dropped_points) * 100;
  const fireAndFlooding =
    statistics.frags -
    statistics.frags_by_tpd -
    statistics.frags_by_planes -
    statistics.frags_by_main -
    statistics.frags_by_atba -
    statistics.frags_by_ram;
  const averageFrags = statistics.frags / statistics.battles_count;
  const averagePlanesKilled = statistics.planes_killed / statistics.battles_count;
  const averageDamageDealt = statistics.damage_dealt / statistics.battles_count;
  const averageXp = statistics.premium_exp / statistics.battles_count;
  const averageShipsSpotted = statistics.ships_spotted / statistics.battles_count;
  const averageScoutingDamage = statistics.scouting_damage / statistics.battles_count;
  const averageAgro = (statistics.art_agro + statistics.tpd_agro) / statistics.battles_count_510;
  const agroTotal = statistics.art_agro + statistics.tpd_agro;

  const leftColumn: StatisticsColumn = [
    [
      {
        label: t('Battles played'),
        value: roundNumbers(statistics.battles_count),
      },
      {
        label: t('Victories'),
        value: [roundNumbers(winsPercent, true), roundNumbers(statistics.wins)],
      },
      {
        label: t('Defeats'),
        value: [roundNumbers(lossesPercent, true), roundNumbers(statistics.losses)],
      },
      {
        label: t('Draws'),
        value: roundNumbers(draws),
      },
      {
        label: t('Battles survived'),
        value: roundNumbers(statistics.survived),
        spacer: true,
      },
      {
        label: t('Main battery guns hit ratio'),
        value: roundNumbers(mainBatteryHitsPercent, true),
      },
      {
        label: t('Torpedoes hit ratio'),
        value: roundNumbers(torpedoesHitsPercent, true),
        spacer: true,
      },
      {
        label: t('Contribution to capture'),
        value: roundNumbers(capturedPercent, true),
      },
      {
        label: t('Contribution to defense'),
        value: roundNumbers(droppedPercent, true),
        spacer: true,
      },
      {
        label: t('Damage'),
        value: roundNumbers(statistics.damage_dealt),
      },
      {
        label: t('XP'),
        value: roundNumbers(statistics.premium_exp),
      },
    ],
  ];
  const middleColumn: StatisticsColumn = [
    [
      {
        label: t('Warships destroyed'),
        value: roundNumbers(statistics.frags),
      },
      {
        label: t('By main battery guns'),
        value: roundNumbers(statistics.frags_by_main),
      },
      {
        label: t('By secondary armament'),
        value: roundNumbers(statistics.frags_by_atba),
      },
      {
        label: t('By torpedoes'),
        value: roundNumbers(statistics.frags_by_tpd),
      },
      {
        label: t('By aviation'),
        value: roundNumbers(statistics.frags_by_planes),
      },
      {
        label: t('By flooding and fire'),
        value: roundNumbers(fireAndFlooding),
      },
      {
        label: t('By ramming'),
        value: roundNumbers(statistics.frags_by_ram),
      },
      {
        label: t('With depth charges'),
        value: roundNumbers(statistics.frags_by_dbomb),
        spacer: true,
      },
      {
        label: t('Aircraft destroyed'),
        value: roundNumbers(statistics.planes_killed),
        spacer: true,
      },
      {
        label: t('Ships spotted'),
        value: roundNumbers(statistics.ships_spotted),
      },
      {
        label: t('Spotting damage'),
        value: roundNumbers(statistics.scouting_damage),
        spacer: true,
      },
      {
        label: t('Potential damage'),
        value: roundNumbers(agroTotal),
        spacer: true,
      },
    ],
  ];
  const rightColumn: StatisticsColumn = [
    [
      {
        label: t('Average Score per Battle'),
        value: '',
      },
      {
        label: t('Warships destroyed'),
        value: roundNumbers(averageFrags),
      },
      {
        label: t('Aircraft destroyed'),
        value: roundNumbers(averagePlanesKilled),
      },
      {
        label: t('Damage'),
        value: roundNumbers(averageDamageDealt),
      },
      {
        label: t('XP'),
        value: roundNumbers(averageXp),
      },
      {
        label: t('Ships spotted'),
        value: roundNumbers(averageShipsSpotted),
      },
      {
        label: t('Spotting damage'),
        value: roundNumbers(averageScoutingDamage),
      },
      {
        label: t('Potential damage'),
        value: roundNumbers(averageAgro),
      },
    ],

    [
      {
        label: t('Most per Battle'),
        value: '',
      },
      {
        label: t('Warships destroyed'),
        value: roundNumbers(statistics.max_frags),
      },
      {
        label: t('Aircraft destroyed'),
        value: roundNumbers(statistics.max_planes_killed),
      },
      {
        label: t('Damage'),
        value: roundNumbers(statistics.max_damage_dealt),
      },
      {
        label: t('XP'),
        value: roundNumbers(statistics.max_premium_exp),
      },
      {
        label: t('Ships spotted'),
        value: roundNumbers(statistics.max_ships_spotted),
      },
      {
        label: t('Spotting damage'),
        value: roundNumbers(statistics.max_scouting_damage),
      },
      {
        label: t('Potential damage'),
        value: roundNumbers(statistics.max_total_agro),
      },
    ],
  ];

  const data: Array<StatisticsColumn> = [leftColumn, middleColumn, rightColumn];
  return data;
};

export const getAvailableBattleTypes = (account?: Account, allTypes?: boolean): BattleTypeName[] => {
  const nonBattleTypesKeys = ['basic', 'seasons', 'rank_info'];

  if (!account || !account.statistics || Object.keys(account.statistics).length === 0 || allTypes) {
    return BATTLE_TYPES.map((battleType) => battleType.id);
  }

  const availableBattleTypes: Set<BattleTypeName> = new Set();

  if (account.statistics) {
    for (const key in account.statistics) {
      if (!nonBattleTypesKeys.includes(key) && !SEASONAL_BATTLE_TYPES.includes(key)) {
        const val = get(account.statistics, key, undefined);
        if (val && Object.keys(val).length > 0) {
          if (RANK_OLD_BATTLE_TYPES.includes(key)) availableBattleTypes.add('rank_old' as BattleTypeName);
          else if (RANK_BATTLE_TYPES.includes(key)) availableBattleTypes.add('rank' as BattleTypeName);
          else if (PVP_BATTLE_TYPES.includes(key)) availableBattleTypes.add('pvp' as BattleTypeName);
          else availableBattleTypes.add(key as BattleTypeName);
        }
      }
      if (key === 'seasons') {
        const val = get(account.statistics, key, undefined);
        if (val && Object.keys(val).length > 0) {
          for (const seasonKey in val) {
            Object.keys(val[seasonKey]).forEach((seasonBattleType: BattleTypeName) => {
              if (!availableBattleTypes.has(seasonBattleType)) {
                if (RANK_OLD_BATTLE_TYPES.includes(seasonBattleType))
                  availableBattleTypes.add('rank_old' as BattleTypeName);
                else if (RANK_BATTLE_TYPES.includes(seasonBattleType))
                  availableBattleTypes.add('rank' as BattleTypeName);
                else availableBattleTypes.add(seasonBattleType);
              }
            });
          }
        }
      }
    }
  }

  return Array.from(availableBattleTypes);
};

export const getAvailableStatBattleTypes = (account?: Account): StatBattleType[] => {
  const nonBattleTypesKeys = ['basic', 'seasons', 'rank_info'];

  const availableBattleTypes: Set<StatBattleType> = new Set();

  if (account?.statistics) {
    for (const key in account.statistics) {
      if (!nonBattleTypesKeys.includes(key) && !SEASONAL_BATTLE_TYPES.includes(key)) {
        const val = get(account.statistics, key, undefined);
        if (val && Object.keys(val).length > 0) {
          availableBattleTypes.add(key as StatBattleType);
        }
      }
      if (key === 'seasons') {
        const val = get(account.statistics, key, undefined);
        if (val && Object.keys(val).length > 0) {
          for (const seasonKey in val) {
            Object.keys(val[seasonKey]).forEach((seasonBattleType: StatBattleType) => {
              if (!availableBattleTypes.has(seasonBattleType)) {
                availableBattleTypes.add(seasonBattleType);
              }
            });
          }
        }
      }
    }
  }
  return Array.from(availableBattleTypes);
};

export const getAvailableDivisions = (
  battleTypeRaw: BattleTypeName,
  divisions: DivisionName[],
  availableBattleTypes: StatBattleType[],
): DivisionName[] => {
  const availableDivisions: DivisionName[] = [];
  divisions.forEach((division) => {
    const battleType = getBattleType(battleTypeRaw, division);
    if (availableBattleTypes.includes(battleType)) {
      availableDivisions.push(division);
    }
  });
  return availableDivisions;
};
