import { NotificationManager } from '@wg/wows-react-uikit';
import get from 'lodash/get';
import set from 'lodash/set';
import { NavigateFunction } from 'react-router-dom';

import { navigateToProfile } from '~/Actions/ActionNavigation';
import vortexClient from '~/clients/vortex';
import {
  ERROR_CODES,
  ROUTES,
  SEARCH_LIMIT,
  SEARCH_MAX_LENGTH,
  SEARCH_MIN_LENGTH,
  SESSION_STORAGE_KEY,
  SESSION_STORAGE_KEY_LANG,
  VORTEX_ACCOUNTS,
  VORTEX_AUTOCOMPLETE,
  VORTEX_SEARCH,
} from '~/constants';
import gqlClient from '~/gql/client';
import { getGlossDataQuery, getGlossVersionQuery } from '~/gql/queries';
import preloaded from '~/preloaded';
import { IAppDispatch, IAppThunk, RootState } from '~/store';
import { appActions } from '~/store/appSlice';
import { searchToObject } from '~/utils';
import {
  getBattleTypesMap,
  getDollsMap,
  getNationsMap,
  getVehiclesMap,
  getVehicleTypesIndexMap,
  getVehicleTypesMap,
} from '~/utils/glossary';

export const appInit =
  (navigate: NavigateFunction, l: any): IAppThunk =>
  (dispatch: IAppDispatch, getState: () => RootState) => {
    const location: Location = l as Location;
    const state = getState();
    const queryParams = searchToObject();
    if (state.app.authSpaId) {
      const spaId = `${state.app.authSpaId}`;
      dispatch(getClanData(spaId, true));
      if (location.pathname === ROUTES.ROOT && !queryParams.query) {
        dispatch(navigateToProfile(navigate, spaId));
      }
    }
  };

export const searchInit = (): IAppThunk => (dispatch: IAppDispatch) => {
  dispatch(
    getGlossaryData(() => {
      dispatch(appActions.searchInit());
    }),
  );
};

export const dropAccount = (): IAppThunk => (dispatch: IAppDispatch) => {
  dispatch(appActions.dropAccount());
};

export const getAccount =
  (id: string, accessCode = ''): IAppThunk =>
  (dispatch: IAppDispatch) => {
    const clb = () => {
      dispatch(appActions.setAccount({}));
      dispatch(getClanData(id));
      dispatch(getStatisticsData(id, accessCode));
      dispatch(getUserAchievements(id, accessCode));
      dispatch(appActions.setAccountFetching({ isFetching: true }));

      let url = `${VORTEX_ACCOUNTS}/${id}`; //274358
      if (accessCode !== '') {
        url = url + `?ac=${accessCode}`;
      }

      vortexClient
        .get<AccountResponse>(url)
        .then((r) => {
          const account = r.data.data[id] as Account;
          account.id = id;
          dispatch(appActions.setAccount({ account, id }));
        })
        .catch((err: any) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(appActions.setAccountFetching({ isFetching: false }));
        });
    };

    dispatch(getGlossaryData(clb));
  };

export const getClanData =
  (id: string, isOwnClan = false): IAppThunk =>
  (dispatch: IAppDispatch) => {
    dispatch(appActions.setClanData({}));
    dispatch(appActions.setClanFetching({ isFetching: true }));
    const url = `${VORTEX_ACCOUNTS}/${id}/clans/`;
    vortexClient
      .get<ClanDataResponse>(url)
      .then((r) => {
        dispatch(appActions.setClanData({ clanData: r.data.data, isOwnClan }));
      })
      .finally(() => {
        dispatch(appActions.setClanFetching({ isFetching: false }));
      });
  };

export const getStatisticsData =
  (id: string, accessCode = ''): IAppThunk =>
  (dispatch: IAppDispatch) => {
    dispatch(appActions.setClanData({}));
    dispatch(appActions.setStatisticsDataFetching({ isFetching: true }));
    let url = `${VORTEX_ACCOUNTS}/${id}/ships/`;

    if (accessCode !== '') {
      url = url + `?ac=${accessCode}`;
    }

    vortexClient
      .get<ShipStatisticsDataResponse>(url)
      .then((r) => {
        const shipStatisticsAccount = r.data.data[id] as ShipStatisticsAccount;
        const shipsData = shipStatisticsAccount.statistics ? Object.values(shipStatisticsAccount.statistics) : [];
        const shipIds = shipStatisticsAccount.statistics ? Object.keys(shipStatisticsAccount.statistics) : [];
        const mappedShips: Array<TableShip> = shipsData.map((ship, index) => {
          return {
            ...ship,
            id: shipIds[index],
          };
        });
        dispatch(appActions.setStatisticsData({ statisticsData: mappedShips }));
        dispatch(setChartsData(mappedShips));
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(appActions.setStatisticsDataFetching({ isFetching: false }));
      });
  };

export const setChartsData =
  (mappedShips: Array<TableShip>): IAppThunk =>
  (dispatch: IAppDispatch, getState: () => RootState) => {
    const state = getState();
    const nationStatisticsMap: NationsStatisticsMap = {};
    const vehicleTypeStatisticsMap: VehicleTypeStatisticsMap = {};
    const vehicleLevelStatisticsMap: VehicleLevelStatisticsMap = {};

    mappedShips.forEach((mappedShip) => {
      const shipInfo = state.app.vehiclesMap[parseInt(mappedShip.id, 10)];
      if (shipInfo) {
        const level = `${shipInfo.level}`;
        for (const battleType in mappedShip) {
          if (battleType === 'seasons') {
            for (const seasonId in mappedShip[battleType]) {
              const seasonData = get(mappedShip, `${battleType}.${seasonId}`, {});
              for (const seasonBattleType in seasonData) {
                const battles_count = get(seasonData, `${seasonBattleType}.battles_count`, 0);
                const prevBattleCountNations = get(
                  nationStatisticsMap,
                  `${shipInfo.nation.name}.${battleType}.${seasonId}.${seasonBattleType}`,
                  0,
                ) as number;
                set(
                  nationStatisticsMap,
                  `${shipInfo.nation.name}.${battleType}.${seasonId}.${seasonBattleType}`,
                  prevBattleCountNations + battles_count,
                );

                const prevBattleCountVehicleType = get(
                  vehicleTypeStatisticsMap,
                  `${shipInfo.type.name}.${battleType}.${seasonId}.${seasonBattleType}`,
                  0,
                ) as number;
                set(
                  vehicleTypeStatisticsMap,
                  `${shipInfo.type.name}.${battleType}.${seasonId}.${seasonBattleType}`,
                  prevBattleCountVehicleType + battles_count,
                );

                const prevBattleCountVehicleLevel = get(
                  vehicleLevelStatisticsMap,
                  `${level}.${battleType}.${seasonId}.${seasonBattleType}`,
                  0,
                ) as number;
                set(
                  vehicleLevelStatisticsMap,
                  `${level}.${battleType}.${seasonId}.${seasonBattleType}`,
                  prevBattleCountVehicleLevel + battles_count,
                );
              }
            }
          } else {
            const battles_count = get(mappedShip, `${battleType}.battles_count`, 0);
            const prevBattleCountNations = get(
              nationStatisticsMap,
              `${shipInfo.nation.name}.${battleType}`,
              0,
            ) as number;
            set(nationStatisticsMap, `${shipInfo.nation.name}.${battleType}`, prevBattleCountNations + battles_count);

            const prevBattleCountVehicleType = get(
              vehicleTypeStatisticsMap,
              `${shipInfo.type.name}.${battleType}`,
              0,
            ) as number;
            set(
              vehicleTypeStatisticsMap,
              `${shipInfo.type.name}.${battleType}`,
              prevBattleCountVehicleType + battles_count,
            );

            const prevBattleCountVehicleLevel = get(vehicleLevelStatisticsMap, `${level}.${battleType}`, 0) as number;
            set(vehicleLevelStatisticsMap, `${level}.${battleType}`, prevBattleCountVehicleLevel + battles_count);
          }
        }
      }
    });

    dispatch(
      appActions.setChartsData({
        nationStatisticsMap,
        vehicleTypeStatisticsMap,
        vehicleLevelStatisticsMap,
      }),
    );
  };

export const setBattleType =
  (battleType: BattleTypeName): IAppThunk =>
  (dispatch: IAppDispatch) => {
    const defaultDivisions: {
      [ley: string]: DivisionName;
    } = {
      ['pvp']: '',
      ['pve']: '',
      ['club']: '',
      ['clan']: '',
      ['brawl']: '',
      ['rank']: 'solo',
      ['rank_old']: 'solo',
    };
    dispatch(appActions.setBattleType({ battleType: battleType }));
    dispatch(appActions.setDivision({ division: defaultDivisions[battleType] }));
  };

export const setDivision =
  (division: DivisionName): IAppThunk =>
  (dispatch: IAppDispatch) => {
    dispatch(
      appActions.setDivision({
        division: division,
      }),
    );
  };

export const getGlossaryData =
  (clb: () => void): IAppThunk =>
  (dispatch: IAppDispatch) => {
    const sessionStorageData = sessionStorage.getItem(SESSION_STORAGE_KEY);
    const sessionStorageDataLang = sessionStorage.getItem(SESSION_STORAGE_KEY_LANG);

    const queryParams = searchToObject();
    const languageCode = queryParams.lang || preloaded.settings.languageCode;

    const updateData = (data: GlossaryQuery) => {
      const vehiclesMap = getVehiclesMap(data);
      const vehicleTypesIndexMap = getVehicleTypesIndexMap(data);
      const vehicleTypesMap = getVehicleTypesMap(data);
      const dollsMap = getDollsMap(data);
      const nationsMap = getNationsMap(data);
      const battleTypesMap = getBattleTypesMap(data);

      dispatch(
        appActions.getGlossaryData({
          encyclopedia: data,
          vehiclesMap: vehiclesMap,
          vehicleTypesIndexMap: vehicleTypesIndexMap,
          vehicleTypesMap: vehicleTypesMap,
          dollsMap: dollsMap,
          nationsMap: nationsMap,
          battleTypesMap: battleTypesMap,
        }),
      );
      clb();
    };

    const getNewData = (lang: string) => {
      gqlClient
        .query({
          query: getGlossDataQuery,
          variables: {
            lang: lang,
          },
        })
        .then((r: any) => {
          const data: GlossaryQuery = {
            ...r.data,
          };
          sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(data));
          sessionStorage.setItem(SESSION_STORAGE_KEY_LANG, languageCode);
          updateData(data);
        });
    };

    if (sessionStorageData) {
      const data: GlossaryQuery = JSON.parse(sessionStorageData);

      gqlClient
        .query({
          query: getGlossVersionQuery,
        })
        .then((r: any) => {
          const versionData: GlossaryQuery = {
            ...r.data,
          };

          if (versionData.version !== data.version || languageCode !== sessionStorageDataLang) {
            getNewData(languageCode);
          } else {
            updateData(data);
          }
        })
        .catch(() => {
          updateData(data);
        });
    } else {
      getNewData(languageCode);
    }
  };

export const setSortField =
  (sortField: CellSelector): IAppThunk =>
  (dispatch: IAppDispatch, getState: () => RootState) => {
    const state = getState();
    const sortDirection = state.app.sortDirection;
    const curSortField = state.app.sortField;
    let newSortDirection = sortDirection;
    if (curSortField === sortField) {
      switch (sortDirection) {
        case -1: {
          newSortDirection = 1;
          break;
        }
        case 1: {
          newSortDirection = -1;
          break;
        }
      }
    } else {
      newSortDirection = -1;
    }
    dispatch(
      appActions.setSortField({
        sortField: sortField,
        sortDirection: newSortDirection,
      }),
    );
  };

export const getUsersAutocomplete =
  (query: string): IAppThunk =>
  (dispatch: IAppDispatch) => {
    if (query && query.length >= SEARCH_MIN_LENGTH && query.length <= SEARCH_MAX_LENGTH) {
      const url = `${VORTEX_AUTOCOMPLETE}${query}/`;
      vortexClient
        .get<SuggestionsResponse>(url)
        .then((r) => {
          dispatch(appActions.setAutocompleteSuggestions({ suggestions: r.data.data }));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      dispatch(appActions.setAutocompleteSuggestions({ suggestions: [] }));
    }
  };

export const getUsersSearch =
  (query?: string, lastName?: string): IAppThunk =>
  (dispatch: IAppDispatch) => {
    if (query) {
      const after = lastName ? `&name_gt=${lastName}` : '';
      const url = `${VORTEX_SEARCH}${query}/?limit=${SEARCH_LIMIT}${after}`;
      vortexClient
        .get<SearchResultsResponse>(url)
        .then((r) => {
          dispatch(appActions.setSearchResults({ searchResults: r.data.data, append: !!lastName }));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      dispatch(appActions.setSearchResults({ searchResults: [], append: false }));
    }
  };

export const inViteToClan = (): IAppThunk => (dispatch: IAppDispatch, getState: () => RootState) => {
  const state = getState();
  const id = state.app.spaId;
  const url = `${VORTEX_ACCOUNTS}/${id}/invite_to_clan/`;
  vortexClient
    .post(url)
    .then(() => {
      NotificationManager.sendWebNotification({
        message: window.notificationMessages.inviteSendMessage,
      });
      dispatch(appActions.setInvitedId({ id }));
    })
    .catch((error) => {
      if (error.response.status === ERROR_CODES.CONFLICT) {
        NotificationManager.sendWebNotification({
          message: window.notificationMessages.inviteSendMessage,
        });
        dispatch(appActions.setInvitedId({ id }));
      } else {
        NotificationManager.sendWebNotification({
          message: window.notificationMessages.inviteSendErrorMessage,
        });
      }
    });
};

export const getShipStatData =
  (shipId: string, clb: () => void): IAppThunk =>
  (dispatch: IAppDispatch, getState: () => RootState) => {
    const state = getState();
    const { battleType, spaId } = state.app;

    const url = `${VORTEX_ACCOUNTS}/${spaId}/ships/${shipId}/${battleType}/`;
    vortexClient
      .get<GetShipStatDataResponse>(url)
      .then((r) => {
        const statistics: BattleStat = get(r.data, `data[${spaId}].statistics[${shipId}][${battleType}]`, undefined);
        if (statistics) {
          dispatch(appActions.setShipStatistics({ statistics }));
          clb();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const getUserAchievements =
  (spaId: string, accessCode = ''): IAppThunk =>
  (dispatch: IAppDispatch) => {
    let url = `${VORTEX_ACCOUNTS}/${spaId}/achievements/`;

    if (accessCode !== '') {
      url = url + `?ac=${accessCode}`;
    }

    vortexClient
      .get<GetAchievementsResponse>(url)
      .then((r) => {
        const achievementsAccount = r.data.data[spaId] as AchievementsAccount;
        if (!achievementsAccount.hidden_profile) {
          dispatch(appActions.setAccountAchievements({ achievements: achievementsAccount.statistics.achievements }));
        }
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {});
  };

export const toggleFilter =
  (key: string, value: string | number | boolean, override = false): IAppThunk =>
  (dispatch: IAppDispatch, getState: () => RootState) => {
    const state = getState();
    const filters = [...state.app.filters];

    const strFilter = `${key}#${value}`;
    let newFilters: Array<string> = filters.includes(strFilter)
      ? filters.filter((f) => f !== strFilter)
      : filters.concat([strFilter]);
    if (override) {
      newFilters = newFilters.filter((f) => !f.includes(`${key}#`));
      if (`${value}`.length > 0) {
        newFilters.push(strFilter);
      }
    }

    dispatch(appActions.toggleFilter({ filters: newFilters }));
  };

export const dropFilters = (): IAppThunk => (dispatch: IAppDispatch) => {
  dispatch(appActions.toggleFilter({ filters: [] }));
};
