import '@wg/wows-css-uikit/index.css';
import '@wg/wows-entities/index.css';
import '~/styles/global.scss';

import { wowsEntities } from '@wg/wows-entities';
import { TooltipProvider } from '@wg/wows-react-uikit';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';
import { StickyContainer } from 'react-sticky';

import Background from '~/Components/Background/Background';
import Notifications from '~/Components/Notifications/Notifications';
import Achievements from '~/Components/Pages/Achievements/Achievements';
import Profile from '~/Components/Pages/Profile/Profile';
import Search from '~/Components/Pages/Search/Search';
import ToTopArrow from '~/Components/ToTopArrow/ToTopArrow';
import toTopButtonStyles from '~/Components/ToTopArrow/ToTopArrow.scss';
import { ROUTES } from '~/constants';
import preloaded from '~/preloaded';
import { store } from '~/store';
import dwhExport from '~/utils/dwhExport';
import { t } from '~/utils/localization';

import App from './App';
import { items } from '@wg/wows-entities/const';

const router = createBrowserRouter([
  {
    path: ROUTES.ROOT,
    element: <App />,
    children: [
      {
        index: true,
        element: <Search />,
      },
      {
        path: ROUTES.PROFILE_ACCESS_CODE,
        element: <Profile />,
      },
      {
        path: ROUTES.PROFILE,
        element: <Profile />,
      },
      {
        path: ROUTES.ACHIEVEMENTS,
        element: <Achievements />,
      },
    ],
  },
]);

const Root = () => {
  document.title = t('Player Profile');

  dwhExport.appStart();

  useEffect(() => {
    wowsEntities.init({
      vortexBaseUrl: `${preloaded.urls.vortex}`,
      languageCode: preloaded.settings.languageCode,
    }).then(() => {
      wowsEntities.warmingUp([items.MASTERY]);
    });
  }, []);

  return (
    <>
      <Background />
      <Provider store={store}>
        <TooltipProvider>
          <StickyContainer>
            <RouterProvider router={router} />
          </StickyContainer>
        </TooltipProvider>
        <Notifications />
        <ScrollToTop smooth component={<ToTopArrow />} className={toTopButtonStyles.button} />
      </Provider>
    </>
  );
};

export default Root;
