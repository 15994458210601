import Statistics from '~/Components/Statistics/Statistics';
import StatisticsHeader from '~/Components/StatisticsHeader/StatisticsHeader';
import { getStatisticsData, getStatisticsHeaderData } from '~/utils/statistics';

import styles from './DetailInfo.scss';

export interface DetailInfo_Props {
  statistics: BattleStat;
  battleType: StatBattleType;
  totalStats: ShipStatisticsAccountStatisticsItemShort;
}

const DetailInfo = (props: DetailInfo_Props) => {
  const { statistics } = props;

  return (
    <div className={styles.wrapper}>
      <StatisticsHeader data={getStatisticsHeaderData(statistics, props.totalStats, props.battleType)} />
      <Statistics data={getStatisticsData(statistics)} />
    </div>
  );
};

export default DetailInfo;
